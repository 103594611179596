const FlexibleMap = () => {
    const maps = document.querySelectorAll('.map');

    for (const map of maps) {
        renderMap(map);
    }
}

const renderMap = mapComponent => {
    const mapData = initMap(mapComponent);
    const bounds = new google.maps.LatLngBounds();
    const infoWindow = new google.maps.InfoWindow();
    const modalActiveClass = 'map__modal--active';

    plotMarkers(mapData.locations, mapData.map, bounds, infoWindow, modalActiveClass, mapComponent);

    $(mapComponent).find('.map__modal-close').click(e => {
        $(mapComponent).find('.map__modal').removeClass(modalActiveClass);
    })
}

const initMap = mapComponent => {
    const locationElements = $(mapComponent).find('.map__location');
    const mapContainer = $(mapComponent).find('.map__map')[0];
    const locations = [];

    locationElements.each((index, loc) => {
        locations.push({ ...$(loc).data() });
    });

    return {
        map: new google.maps.Map(mapContainer, {
            zoom: 10,
            center: {
                lat: 0, lng: 0
            },
            styles: getStyles()
        }),
        locations: locations
    }
}

const plotMarkers = (locations, map, bounds, infoWindow, modalActiveClass, mapComponent) => {
    Array.prototype.forEach.call(locations, (location) => {
        const marker = new google.maps.Marker({
            position: {
                lat: parseFloat(location.lat),
                lng: parseFloat(location.lng)
            },
            map: map,
            icon: getIconStyles(location.type),
        })

        console.log(marker);

        marker.addListener('click', e => {
            const modal = $(mapComponent).find('.map__modal')[0];
            const isActive = modal.classList.contains(modalActiveClass);
            const modalTitle = $(modal).find('.map__modal-title')[0];
            const modalAddress = $(modal).find('.map__modal-address')[0];
            const modalButton = $(modal).find('.map__btn')[0];

            modal.classList.remove(modalActiveClass);
            
            const renderContent = () => {
                modalTitle.innerHTML = location.title;
                modalAddress.innerHTML = location.address;
                modalButton.href = `https://maps.google.com/?ll=${location.lat},${location.lng}`;
                modal.classList.add(modalActiveClass);
            }

            if (isActive) {
                setTimeout(() => {
                    renderContent()
                }, 400)
            } else {
                renderContent();
            }
        })

        bounds.extend(marker.position);
        map.fitBounds(bounds);
    });
}

const getStyles = () => {
    return [
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e9e9e9"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 29
                },
                {
                    "weight": 0.2
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 18
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dedede"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "saturation": 36
                },
                {
                    "color": "#333333"
                },
                {
                    "lightness": 40
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f2f2f2"
                },
                {
                    "lightness": 19
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 17
                },
                {
                    "weight": 1.2
                }
            ]
        }
    ];
}

const getIconStyles = (type) => {

    switch(type) {
        case 'head-office':
            return {
                path: "M23.175.019C36.705-.51 47.848 10.3 47.848 23.747 47.583 37.46 35.38 50.904 25.297 59.604a2.42 2.42 0 0 1-2.654 0C12.032 50.375-1.234 35.875.093 21.373 1.42 9.775 11.237.547 23.174.019z",
                fillColor: "#132049",
                fillOpacity: 1,
                scale: 0.75,
                strokeOpacity: 0,
                size: new google.maps.Size(42, 53),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(21, 53)
            }
        case 'mill':
            return {
                path: "M23.175.019C36.705-.51 47.848 10.3 47.848 23.747 47.583 37.46 35.38 50.904 25.297 59.604a2.42 2.42 0 0 1-2.654 0C12.032 50.375-1.234 35.875.093 21.373 1.42 9.775 11.237.547 23.174.019z",
                fillColor: "#3D5FD3",
                fillOpacity: 1,
                scale: 0.75,
                strokeOpacity: 0,
                size: new google.maps.Size(42, 53),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(21, 53)
            }
        case 'wheat-area':
            return {
                path: "M23.175.019C36.705-.51 47.848 10.3 47.848 23.747 47.583 37.46 35.38 50.904 25.297 59.604a2.42 2.42 0 0 1-2.654 0C12.032 50.375-1.234 35.875.093 21.373 1.42 9.775 11.237.547 23.174.019z",
                fillColor: "#AB9777",
                fillOpacity: 1,
                scale: 0.75,
                strokeOpacity: 0
            }
        default:
            return {
                path: "M23.175.019C36.705-.51 47.848 10.3 47.848 23.747 47.583 37.46 35.38 50.904 25.297 59.604a2.42 2.42 0 0 1-2.654 0C12.032 50.375-1.234 35.875.093 21.373 1.42 9.775 11.237.547 23.174.019z",
                fillColor: "#3D5FD3",
                fillOpacity: 1,
                scale: 0.75,
                strokeOpacity: 0
            }
    }
}

export default FlexibleMap;