import $ from 'jquery';
import 'slick-carousel';

const HeroSlider = () => {

    const heroCarousel = $('.hero__image');

    heroCarousel.slick({
        lazyLoad: 'ondemand',
        autoplay: true,
        autoplaySpeed: 4000,
        infinite: true,
        speed: 750,
        fade: true,
        cssEase: 'linear',
        draggable:false,
        pauseOnFocus: false,
        pauseOnHover: false,
        swipe: false,
        touchMove: false,
        arrows: false,
        zIndex: 10
    });

}

export default HeroSlider;