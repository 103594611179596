// const watchHeader = () => {

  // // When the user scrolls the page, execute myFunction
  // window.onscroll = function() {StickNav()};

  // // Get the navbar
  // var navbar = document.getElementById("navbar");
  // var main = document.querySelector('main');

  // // Get the offset position of the navbar
  // var sticky = navbar.offsetTop;

  // // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
  // function StickNav() {
  //   if (window.pageYOffset >= sticky) {
  //     navbar.classList.add("sticky");
  //     main.classList.add("sub-nav-active");
  //   } else {
  //     navbar.classList.remove("sticky");
  //     main.classList.remove("sub-nav-active");
  //   }
  // }

// }

// export default watchHeader;

import Headroom from 'headroom.js';

const watchHeader = (elem) => {
    let options = {
        "offset": 160,
        "tolerance": 0,
        "classes": {
            pinned : "header--fixed",
            unpinned : "header--unpinned",
            notTop : "header--fixed",
        },

        onTop: function () {
            elem.classList.remove('header--fixed');
        }
    }

    let headroom = new Headroom(elem, options);

    headroom.init();

};

export default watchHeader;