let mobileElements = {
  mobileMenu: document.querySelector('.mobile-menu'),
  mobileMenuTrigger: document.querySelector('.header__mobileMenuTrigger'),
  body: document.querySelector('body'),
  header: document.querySelector('.header'),
  items: document.querySelectorAll('.menu-item')
}

let { mobileMenuTrigger, mobileMenu, body, header, items } = mobileElements;

const animateItems = () => {

  let interval = 50;

  items.forEach((i, index) => {
    i.addEventListener('click' , () => {
      toggleMenu();
    });
    if (!i.classList.contains('reveal')) {
      setTimeout(function () {
        i.classList.toggle('reveal');
      }, index * interval);
    } else {
      i.classList.toggle('reveal');
    }
  });

}

function watchDrops() {
  let drops = document.querySelectorAll('.mobile-menu__drop');
  Array.from(drops).forEach(drop => {
    drop.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      drop.parentElement.classList.toggle('child-open');
      drop.nextElementSibling.nextElementSibling.classList.toggle('sub-menu--active');
      drop.firstElementChild.classList.toggle('rotate');
    });
  });
}

function toggleMenu() {

  watchDrops();

  mobileMenu.classList.toggle('mobile-menu--active');
  mobileMenuTrigger.classList.toggle('header__mobileMenuTrigger--opened');
  body.classList.toggle('stopScroll');
  header.classList.toggle('header--menuOpen');

  animateItems();

}

function MobileMenu () {

  Array.from(items).forEach(item => {
    if (item.classList.contains('menu-item-has-children')) {
      item.insertAdjacentHTML('afterbegin', `
        <span class="mobile-menu__drop flex flex--centerOnly"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="8"><path d="M6.232 7.712L.308 1.773A1.038 1.038 0 1 1 1.766.3L6.96 5.512 12.153.3A1.032 1.032 0 0 1 13.61 1.76L7.687 7.712A1.027 1.027 0 0 1 6.959 8a1.03 1.03 0 0 1-.727-.288z" fill="#A7A9AB" fill-rule="evenodd"/></svg></span>
      `)
    }
  })

  mobileMenuTrigger.addEventListener('click', () => toggleMenu());

}

export default MobileMenu;