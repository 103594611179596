const elements = {
  trigger: document.querySelector('.search-trigger'),
  modal: document.querySelector('.search'),
  input: document.querySelector('.search__input'),
  close: document.querySelector('.search__close')
}


const hideSearch = () => {
  elements.modal.classList.remove('search--active');
  elements.input.value = "";
}

const showSearch = () => {
  elements.modal.classList.add('search--active');
  elements.input.focus();
  elements.close.addEventListener('click' , () => hideSearch());
  document.addEventListener('keydown', (e) => e.key === "Escape" ? hideSearch() : null);
}

const toggleSearch = () => {
  elements.modal.classList.contains('search--active') ? hideSearch() : showSearch();
}

const Search = () => elements.trigger.addEventListener('click', () => toggleSearch());

export default Search;