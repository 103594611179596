import $ from 'jquery';
import 'slick-carousel';

const GallerySlider = () => {

  let galleryCarousel = $('.gallery__slider');

  galleryCarousel.slick({
    lazyLoad: 'ondemand',
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    centerMode: true,
    centerPadding: '400px',
    accessibility: false,
    initialSlide: 1,
    nav: true,
    mouseDrag: true,
    prevArrow: '.gallery__buttonPrev',
    nextArrow: '.gallery__buttonNext',
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: '300px',
        }
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '200px',
        }
      },
      {
        breakpoint: 640,
        settings: {
          centerPadding: '50px',
        }
      },
      {
        breakpoint: 300,
        settings: {
          centerPadding: '0px',
        }
      },
    ]
  });

};

export default GallerySlider;