import $ from 'jquery';
import 'slick-carousel';

const TextImage = () => {
    const textImageSliders = document.querySelectorAll('.image-text__slider');

    for (let i = 0; i < textImageSliders.length; i++) {
        createSlider(textImageSliders[i]);
    }
}

const createSlider = el => {
    $(el).slick({
        lazyLoad: 'ondemand',
        infinite: true,
        speed: 750,
        draggable:false,
        pauseOnFocus: false,
        pauseOnHover: false,
        swipe: false,
        touchMove: false,
        arrows: true,
        zIndex: 10
    })
}

export default TextImage;