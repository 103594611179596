import LazyLoad from 'vanilla-lazyload/dist/lazyload.js';
import initMap from './components/Map';
import FancyBox from './components/FancyBox';
import Datepicker from './components/Datepicker';
import HeroSlider from './components/HeroSlider';
import GallerySlider from './components/GallerySlider';
import MobileMenu from './components/MobileMenu';
import watchHeader from './components/HeadRoom';
import Search from './components/Search';
import MenuDropdowns from './components/MenuDropdowns';
import TextImage from './components/TextImage';
import FlexibleMap from './components/FlexibleMap';
import ContactMap from './components/ContactMap';

import AOS from 'aos';
import objectFitImages from 'object-fit-images';

new LazyLoad({
  elements_selector: ".lazy",
  threshold: 1000,
  skip_invisible: false
});

{
  AOS.init();
  objectFitImages();

  //ImageLoader(loader);
  FancyBox();
}

// Search
{
  let searchElement = document.querySelector('.search-trigger');
  if (typeof searchElement != "undefined" && searchElement != null) Search();
}

{
  let header = document.querySelector('header');
  if (typeof header != "undefined" && header != null) {
    watchHeader(header);
  }
}

{
  let mobileMenuElement = document.querySelector('.mobile-menu');
  if (typeof mobileMenuElement != "undefined" && mobileMenuElement != null) {
    MobileMenu();
  }
}

{
  let hero = document.querySelector('.hero');
  if (typeof hero != "undefined" && hero != null)
    HeroSlider();
}

{
  let ACFmap = document.querySelector('.acf-map');

  if (typeof ACFmap != "undefined" && ACFmap != null) {
    initMap();
  }
}

{
  let gallerySliderElement = document.querySelector('.gallery__slider');
  if (typeof gallerySliderElement != "undefined" && gallerySliderElement != null)
  GallerySlider();
}

{
  let datepickerElement = document.querySelector('.datepicker');

  if (typeof datepickerElement != "undefined" && datepickerElement != null) {
    Datepicker();
  }
}

{
  const navbar = document.querySelector('header');

  if (typeof navbar != 'undefined' && navbar) {
    MenuDropdowns();
  }
}

{
  const textImage = document.querySelector('.image-text');

  if (typeof textImage != 'undefined' && textImage) {
    TextImage();
  }
}

{
  const map = document.querySelector('.map');

  if (typeof map != 'undefined' && map) {
    FlexibleMap();
  }
}

{
  const contactMap = document.querySelector('.contact-map');

  if (typeof contactMap != 'undefined' && contactMap) {
    ContactMap();
  }
}

// Style Gravity File Uploader.

{

  let uploadClass = '.ginput_container_fileupload';
  let uploaders = document.querySelectorAll(`${uploadClass}`);
  let label =  `<label class="uploadLabel">Choose File</label>`;

  if (typeof uploaders != "undefined" && uploaders != null) {
    Array.prototype.forEach.call(document.querySelectorAll(`${uploadClass}`), function (upload) {
      upload.insertAdjacentHTML("afterbegin", label);
      let uploadInput =  upload.children[2];
      uploadInput.addEventListener('change', (e) => {
        let fullPath = uploadInput.value;
        let startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
        let filename = fullPath.substring(startIndex);
        if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
            filename = filename.substring(1);
        }
        if (filename.length >= 1) {
          upload.children[0].innerHTML = filename;
        } else {
          upload.children[0].innerHTML = 'Choose File';
        }
      })
    });
  }
}