
const MenuDropdowns = () => {
    const toggles = document.querySelectorAll('.main-menu__item--parent');

    Array.from(toggles).forEach(toggle => {
        let toggleHover = false;
        let dropdownHover = false;

        const dropdown = toggle.querySelector('.main-menu__sub-menu');

        toggle.querySelector('.main-menu__link').insertAdjacentHTML('beforeend', `<img class="main-menu__link-dropdown" alt="Down Arrow" src="/app/themes/adaptable/src/assets/images/arrow-down.svg" />`);

        toggle.addEventListener('mouseenter', e => {
            toggleHover = true;
            dropdown.classList.add('main-menu__sub-menu--active');
            toggle.classList.add('active');
        });

        toggle.addEventListener('mouseleave', e => {
            toggleHover = false;

            setTimeout(() => {
                if (!toggleHover && !dropdownHover) {
                    dropdown.classList.remove('main-menu__sub-menu--active');
                    toggle.classList.remove('active');
                }
            }, 500);
        });

        dropdown.addEventListener('mouseenter', e => {
            dropdownHover = true;
        });

        dropdown.addEventListener('mouseleave', e => {
            dropdownHover = false;

            setTimeout(() => {
                if (!toggleHover && !dropdownHover) {
                    dropdown.classList.remove('main-menu__sub-menu--active');
                    toggle.classList.remove('active');
                }
            }, 500);
        })
    })
}

export default MenuDropdowns;